import React from "react"
import { Provider } from "react-redux"
import { App } from "./src/components/App/App"
import { Modal } from "./src/components/Modal/Modal"
import { SnackBar } from "./src/components/Snack/SnackBar"

import { init } from "./src/redux/store"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  const { store } = init()
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <App>
        <>
          {element}
          <SnackBar />
          <Modal />
        </>
      </App>
    </Provider>
  )
}
