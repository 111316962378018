import { PoemEntity } from "../../entities/PoemEntity";

export const store = "REDUX_POEMS_STORE";
export const update = "REDUX_POEMS_UPDATE";
export const remove = "REDUX_POEMS_REMOVE";
export const add = "REDUX_POEMS_ADD";
export const next = "REDUX_POEMS_STORE_NEXT";
export const previous = "REDUX_POEMS_STORE_PREVIOUS";
export const fetching = "REDUX_POEMS_FETCHING";
export const fetchEnd = "REDUX_POEMS_FETCH_END";

export interface storeAction {
  type: typeof store;
  payload: { poems: PoemEntity[] };
}

export interface updateAction {
  type: typeof update;
  payload: { poem: PoemEntity };
}

export interface removeAction {
  type: typeof remove;
  payload: { poemId: PoemEntity["id"] };
}

export interface addAction {
  type: typeof add;
  payload: { poem: PoemEntity };
}

export interface fetchingAction {
  type: typeof fetching;
}

export interface fetchEndAction {
  type: typeof fetchEnd;
}

export interface nextAction {
  type: typeof next;
}

export interface previousAction {
  type: typeof previous;
}

export type PoemsActionTypes =
  | storeAction
  | removeAction
  | addAction
  | updateAction
  | fetchEndAction
  | fetchingAction
  | nextAction
  | previousAction;
