import { SyllaberEntity } from "../../entities/SyllaberEntity"

export const reset = "REDUX_EDITOR_RESET"
export const onEdit = "REDUX_EDITOR_ON_EDIT"
export const selectCategory = "REDUX_EDITOR_SELECT_CATEGORY"
export const closeCategoryModal = "REDUX_EDITOR_CLOSE_CATEGORY_MODAL"
export const openCategoryModal = "REDUX_EDITOR_OPEN_CATEGORY_MODAL"
export const setEditMode = "REDUX_EDITOR_SET_EDIT_MODE"
export const applyHighlight = "REDUX_EDITOR_APPLY_HIGHLIGHT"
export const storeAugmented = "REDUX_EDITOR_STORE_AUGMENTED"
export const onSave = "REDUX_EDITOR_ON_SAVE"
export const fetching = "REDUX_EDITOR_FETCHING"
export const fetchEnd = "REDUX_EDITOR_FETCH_END"
export const editionDataFetching = "REDUX_EDITOR_EDITION_FETCHING"
export const editionDataFetchEnd = "REDUX_EDITOR_EDITION_FETCH_END"

export interface resetAction {
  type: typeof reset
}

export interface closeCategoryModalAction {
  type: typeof closeCategoryModal
}

export interface openCategoryModalAction {
  type: typeof openCategoryModal
}

export interface openCategoryModalAction {
  type: typeof openCategoryModal
}

export interface setEditModeAction {
  type: typeof setEditMode
  payload: { id: string }
}

export interface selectCategoryAction {
  type: typeof selectCategory
  payload: { id: string }
}

export interface onEditAction {
  type: typeof onEdit
  payload: { content: string }
}

export interface applyHighlightAction {
  type: typeof applyHighlight
}

export interface storeAugmentedAction {
  type: typeof storeAugmented
  payload: { augmented: SyllaberEntity }
}

export interface fetchingAction {
  type: typeof fetching
}

export interface fetchEndAction {
  type: typeof fetchEnd
}

export interface editionFetchingAction {
  type: typeof editionDataFetching
}

export interface editionFetchEndAction {
  type: typeof editionDataFetchEnd
}

export type EditorActionTypes =
  | onEditAction
  | fetchEndAction
  | setEditModeAction
  | fetchingAction
  | closeCategoryModalAction
  | openCategoryModalAction
  | selectCategoryAction
  | editionFetchEndAction
  | editionFetchingAction
  | resetAction
  | storeAugmentedAction
  | applyHighlightAction
