import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as types from "./types";
import * as snack from "../snack/actions";
import * as Models from "../../services/models";
import { RootState } from "../store";

export const store = (
  payload: types.storeAction["payload"]
): types.PoemsActionTypes => ({
  type: types.store,
  payload,
});

export const update = (
  payload: types.updateAction["payload"]
): types.PoemsActionTypes => ({
  type: types.update,
  payload,
});

export const remove = (
  payload: types.removeAction["payload"]
): types.PoemsActionTypes => ({
  type: types.remove,
  payload,
});

export const add = (
  payload: types.addAction["payload"]
): types.PoemsActionTypes => ({
  type: types.add,
  payload,
});

export const next = (): types.PoemsActionTypes => ({
  type: types.next,
});

export const previous = (): types.PoemsActionTypes => ({
  type: types.previous,
});

export const fetching = (): types.PoemsActionTypes => ({
  type: types.fetching,
});

export const fetchEnd = (): types.PoemsActionTypes => ({
  type: types.fetchEnd,
});

const formatError = (code: string) => {
  const messages = {
    "auth/invalid-email": "L'email ne respecte pas le bon format",
    "auth/user-not-found": "L'utilisateur n'existe pas",
    "auth/invalid-password": "Le mot de passe ne respecte pas le bon format",
    "auth/user-exist": "Le nom d'utilisateur existe déjà",
    "auth/password-validation-failed": "Les mots de passe ne coincident pas",
    "auth/wrong-password": "Le mot de passe est invalide",
    "auth/email-already-in-use": "L'email est déjà utilisé",
    "auth/not-doctrine": "Seulement les adresse email Doctrine sont autorisées",
    default: "Une erreur est survenue",
  };

  // @ts-ignore
  return messages[code] || messages.default;
};

const catcher = (dispatcher: ThunkDispatch<any, any, any>) => (error: {
  code: string;
  message: string;
}) => {
  dispatcher(fetchEnd());
  return dispatcher(
    snack.create({ message: formatError(error.code), type: "error" })
  );
};

export const fetchPoems = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  dispatcher(fetching());

  return Models.findPoems({ limit: 100 })
    .then((poems) => {
      dispatcher(store({ poems }));
      return dispatcher(fetchEnd());
    })
    .catch(catcher(dispatcher));
};
