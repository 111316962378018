import { SnackEntity } from "../../entities/SnackEntity"
import * as types from "./types"

interface SnackState {
  snacks: Array<SnackEntity>
}

const initialState: SnackState = {
  snacks: [],
}

export function snackReducer(
  state = initialState,
  action: types.SnackActionTypes
): SnackState {
  if (action.type === types.store) {
    return {
      ...state,
      snacks: [...state.snacks, { ...action.payload }],
    }
  }

  if (action.type === types.remove) {
    const snacks = state.snacks.filter(snack => {
      return snack.id !== action.payload.id
    })

    return { snacks }
  }

  return state
}
