import * as constants from "./types"
import { PoemEntity } from "../../entities/PoemEntity"
import { actions } from "../actions"

interface PoemsState {
  poems: Array<PoemEntity>
  selectedPoem: PoemEntity | null
  selected: number
  havePrevious: boolean
  haveNext: boolean
  fetching: boolean
}

const initialState: PoemsState = {
  poems: [],
  selectedPoem: null,
  selected: 0,
  fetching: false,
  haveNext: false,
  havePrevious: false,
}

export function poemsReducer(
  state = initialState,
  action: constants.PoemsActionTypes
): PoemsState {
  if (action.type === constants.store) {
    return {
      ...state,
      poems: action.payload.poems,
      selectedPoem: action.payload.poems[0],
      havePrevious: false,
      haveNext: true,
      selected: 0,
    }
  }

  if (action.type === constants.update) {
    return {
      ...state,
      poems: state.poems.map(poem => {
        if (poem.id === action.payload.poem.id) return action.payload.poem
        return poem
      }),
    }
  }

  if (action.type === constants.remove) {
    const poems = state.poems.filter(({ id }) => action.payload.poemId !== id)

    return {
      ...state,
      poems,
      selectedPoem: poems[0],
      havePrevious: false,
      haveNext: true,
      selected: 0,
    }
  }

  if (action.type === constants.add) {
    return {
      ...state,
      poems: [action.payload.poem, ...state.poems],
      selectedPoem: action.payload.poem,
      havePrevious: false,
      haveNext: true,
      selected: 0,
    }
  }

  if (action.type === constants.previous) {
    const havePrevious = state.selected - 1 > 0
    const selected = state.selected - 1 <= 0 ? 0 : state.selected - 1

    return {
      ...state,
      selected,
      selectedPoem: state.poems[selected],
      havePrevious,
      haveNext: true,
    }
  }

  if (action.type === constants.next) {
    const haveNext = state.selected + 1 < state.poems.length - 1
    const selected =
      state.selected >= state.poems.length - 1
        ? state.selected
        : state.selected + 1

    return {
      ...state,
      selected,
      selectedPoem: state.poems[selected],
      haveNext,
      havePrevious: true,
    }
  }

  if (action.type === constants.fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === constants.fetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  return state
}
