import { Line, SyllaberEntity, Word } from "../entities/SyllaberEntity";

export class SyllaberService {
  static reconciliation(base: SyllaberEntity, augmented: SyllaberEntity) {
    const reconciliation: SyllaberEntity = {
      characters: 0,
      words: 0,
      lines: [],
    };

    base.lines.forEach((line, index) => {
      const words: Word[] = [];

      line.words.forEach((word, i) => {
        if (word.word === augmented.lines[index]?.words[i]?.word) {
          words.push({
            cuttout: augmented.lines[index]?.words[i]?.cuttout,
            word: augmented.lines[index]?.words[i]?.word,
          });
        } else {
          words.push({
            cuttout: word.cuttout,
            word: word.word,
          });
        }
      });

      reconciliation.lines.push({
        syllables: words.reduce((acc, { cuttout }) => acc + cuttout.length, 0),
        words,
      });
    });

    reconciliation.words = reconciliation.lines.reduce((acc, line) => {
      return line.words.length + acc;
    }, 0);

    reconciliation.characters = reconciliation.lines.reduce((acc, line) => {
      return line.words.map(({ word }) => word).join("").length + acc;
    }, 0);

    return reconciliation;
  }

  static toHtml(syllaber: SyllaberEntity, rules: number[]) {
    const element = document.createElement("div");

    syllaber.lines.forEach((line, index) => {
      const $line = document.createElement("div");
      const empty = rules[index] - line.syllables;

      if (empty === 0) $line.classList.add("success");

      line.words.forEach((word, index, words) => {
        const $word = document.createElement("span");

        $word.classList.add("word");

        word.cuttout.forEach((cuttout) => {
          const $span = document.createElement("span");

          $span.classList.add("cuttout");

          $span.innerHTML = cuttout;
          $word.appendChild($span);
        });

        $line.appendChild($word);

        const space = document.createTextNode(" ");

        if (index !== words.length - 1) $line.appendChild(space);
      });

      if (empty > 0) {
        const array = Array.from({ length: empty });

        array.forEach(() => {
          const $span = document.createElement("span");

          $span.classList.add("empty");
          $span.classList.add("cuttout");

          $line.appendChild($span);
        });
      }

      element.appendChild($line);
    });

    return element.innerHTML;
  }

  static fromHtml(html: string): SyllaberEntity {
    const element = document.createElement("div");
    const lines: Array<Line> = [];

    element.innerHTML = html;

    const $lines = element.querySelectorAll("div");

    $lines.forEach((line, index) => {
      const $words = line.querySelectorAll(".word");
      const words: Array<Word> = [];

      $words.forEach((word, index) => {
        const $cuttout = word.querySelectorAll(".cuttout");
        const cuttout: Array<string> = [];

        $cuttout.forEach((element) => {
          cuttout.push(element.textContent || "");
        });

        words.push({
          word: word.textContent?.trim() || "",
          cuttout,
        });
      });

      lines.push({
        words,
        syllables: words.reduce((acc, { cuttout }) => {
          return acc + cuttout.length;
        }, 0),
      });
    });

    return {
      characters: lines.reduce((accumulator, { words }) => {
        return accumulator + words.map(({ word }) => word).join("").length;
      }, 0),
      words: lines.reduce((accumulator, { words }) => {
        return accumulator + words.length;
      }, 0),
      lines,
    };
  }

  static htmlToText(html: string) {
    const lines: string[] = [];
    const $ = document.createElement("div");

    $.innerHTML = html;

    $.querySelectorAll("div").forEach((e) => {
      lines.push(e.textContent || "");
    });

    $.remove();

    return lines.join("\n");
  }
}
