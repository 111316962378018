import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import SuccessLottie from "../../lottie/success.json";
import LoadingLottie from "../../lottie/loading.json";
import { connector, ContainerProps } from "./containers/Modal.container";

export type ModalProps = {
  isOpen: boolean;
  type?: "loader" | "success";
  title?: string;
  content?: string;
  onClose?: Function;
};

export const ModalWrapper: React.FC<ModalProps> = ({
  isOpen,
  title = "Bravo !",
  content = `Ton haïku a bien été publié, <br />n'hésite pas à en publier d'autres.`,
  type = "success",
  onClose = () => false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lottie.loadAnimation({
      //@ts-ignore
      container: ref.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: type === "success" ? SuccessLottie : LoadingLottie,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return !isOpen ? null : (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center overflow-hidden">
      <div
        onClick={() => onClose()}
        className={
          "absolute top-0 left-0 right-0 bottom-0 inset-0 z-10 transition duration-500 ease-in-out bg-gray-600 bg-opacity-50"
        }
      ></div>

      <section
        className={`bg-white rounded-lg py-8 px-8 md:py-10 md:px-10 relative z-20 inline-block max-w-full shadow-lg`}
      >
        <div ref={ref} className="w-56 md:w-64"></div>
        <p className="mt-2 text-lg font-bold text-center text-gray-800">
          {title}
        </p>
        <p
          className="mt-2 text-sm text-center text-gray-500"
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      </section>
    </div>
  );
};

export const ModalContainer: React.FC<ContainerProps> = (props) => (
  <ModalWrapper {...props} />
);

export const Modal = connector(ModalContainer);
