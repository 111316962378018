import axios from "axios";
import { SyllaberEntity } from "../entities/SyllaberEntity";

const endpoint = "https://cxouik8x9l.execute-api.eu-west-1.amazonaws.com/v0";

export const syllaber = async (text: string) => {
  const url = `${endpoint}/syllaber`;
  const { data } = await axios.post<SyllaberEntity>(url, { text });

  return data;
};
