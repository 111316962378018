import * as auth from "./auth/actions"
import * as profile from "./profile/actions"
import * as snack from "./snack/actions"
import * as menu from "./menu/actions"
import * as editor from "./editor/actions"
import * as poems from "./poems/actions"
import * as modal from "./modal/actions"
import * as likes from "./likes/actions"

export const actions = {
  auth,
  profile,
  snack,
  menu,
  modal,
  editor,
  poems,
  likes,
}
