import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as types from "./types";
import * as snack from "../snack/actions";
import * as Models from "../../services/models";
import { RootState } from "../store";

export const storeLikes = (
  payload: types.storeLikesAction["payload"]
): types.LikesActionTypes => ({
  type: types.storeLikes,
  payload,
});

export const like = (
  payload: types.onLikeAction["payload"]
): types.LikesActionTypes => ({
  type: types.onLike,
  payload,
});

export const reset = (): types.LikesActionTypes => ({
  type: types.reset,
});

export const dislike = (
  payload: types.onDislikeAction["payload"]
): types.LikesActionTypes => ({
  type: types.onDislike,
  payload,
});

export type fetchingReturnType = {
  type: typeof types.fetching;
};

export const fetching = (): types.LikesActionTypes => ({
  type: types.fetching,
});

export const fetchEnd = (): types.LikesActionTypes => ({
  type: types.fetchEnd,
});

const formatError = (code: string) => {
  switch (code) {
    case "auth/invalid-email":
      return "L'email ne respecte pas le bon format";
    case "auth/user-not-found":
      return "L'utilisateur n'existe pas";
    case "auth/invalid-password":
      return "Le mot de passe ne respecte pas le bon format";
    case "auth/user-exist":
      return "Le nom d'utilisateur existe déjà";
    case "auth/password-validation-failed":
      return "Les mots de passe ne coincident pas";
    case "auth/wrong-password":
      return "Le mot de passe est invalide";
    case "auth/email-already-in-use":
      return "L'email est déjà utilisé";
    case "auth/not-doctrine":
      return "Seulement les adresse email Doctrine sont autorisées";
    default:
      return "Une erreur est survenue";
  }
};

const catcher = (dispatcher: ThunkDispatch<any, any, any>) => (error: {
  code: string;
  message: string;
}) => {
  dispatcher(fetchEnd());
  return dispatcher(
    snack.create({ message: formatError(error.code), type: "error" })
  );
};

export const fetchLikes = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  dispatcher(fetching());

  const { auth } = getState();

  if (!auth.user || !auth.user.id)
    return console.warn("the user is not connected");

  return Models.getLikesFromUser(auth.user.id)
    .then(async (likes) => {
      await dispatcher(storeLikes({ likes }));
      return dispatcher(fetchEnd());
    })
    .catch(catcher(dispatcher));
};

type FetchToggleType = {
  userId: string;
  poemId: string;
  dispatcher: ThunkDispatch<any, any, any>;
};

const fetchLike = ({ userId, poemId, dispatcher }: FetchToggleType) => {
  return Models.like({ poemId, userId })
    .then(() => {
      dispatcher(like({ id: poemId }));
      return dispatcher(fetchEnd());
    })
    .catch(catcher(dispatcher));
};

const fetchDislike = ({ userId, poemId, dispatcher }: FetchToggleType) => {
  return Models.dislike({ poemId, userId })
    .then(() => {
      dispatcher(dislike({ id: poemId }));
      return dispatcher(fetchEnd());
    })
    .catch(catcher(dispatcher));
};

export const fetchToggle = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  dispatcher(fetching());

  const { poems, likes, auth } = getState();

  if (!poems.selectedPoem) return Promise.reject("the poem does not exist");
  if (!auth.user?.id) return console.warn("the user is not connected");

  const poemId = poems.selectedPoem.id;
  const userId = auth.user.id;
  const isLiked = Boolean(likes.likes.find(({ id }) => id === poemId));

  if (isLiked) return fetchDislike({ userId, poemId, dispatcher });
  return fetchLike({ userId, poemId, dispatcher });
};
