import { createStore, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import { authReducer } from "./auth/reducers"
import { profileReducer } from "./profile/reducers"
import { poemsReducer } from "./poems/reducers"
import { editorReducer } from "./editor/reducers"
import { likesReducer } from "./likes/reducers"
import { snackReducer } from "./snack/reducers"
import { menuReducer } from "./menu/reducers"
import { modalReducer } from "./modal/reducers"

const enhancer = composeWithDevTools(applyMiddleware(thunk))

export const reducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  snack: snackReducer,
  menu: menuReducer,
  editor: editorReducer,
  poems: poemsReducer,
  likes: likesReducer,
  modal: modalReducer,
})

export type RootState = ReturnType<typeof reducers>

export const init = (initialState = {}) => {
  const store = createStore(reducers, initialState, enhancer)

  return { store }
}
