import * as types from "./types"

interface MenuState {
  isOpen: boolean
}

const initialState: MenuState = {
  isOpen: false,
}

export function menuReducer(
  state = initialState,
  action: types.MenuActionTypes
): MenuState {
  if (action.type === types.open) {
    return {
      isOpen: true,
    }
  }

  if (action.type === types.close) {
    return {
      isOpen: false,
    }
  }

  return state
}
