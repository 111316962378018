import { LikeEntity } from "../../entities/LikeEntity";

export const onLike = "REDUX_LIKES_ON_LIKE";
export const onDislike = "REDUX_LIKES_ON_DISLIKE";
export const reset = "REDUX_LIKES_RESET";
export const storeLikes = "REDUX_LIKES_STORE_LIKES";
export const fetching = "REDUX_LIKES_FETCHING";
export const fetchEnd = "REDUX_LIKES_FETCH_END";

export interface storeLikesAction {
  type: typeof storeLikes;
  payload: { likes: Array<LikeEntity> };
}

export interface resetAction {
  type: typeof reset;
}

export interface onLikeAction {
  type: typeof onLike;
  payload: { id: string };
}

export interface onDislikeAction {
  type: typeof onDislike;
  payload: { id: string };
}

export interface fetchingAction {
  type: typeof fetching;
}

export interface fetchEndAction {
  type: typeof fetchEnd;
}

export type LikesActionTypes =
  | onLikeAction
  | storeLikesAction
  | onDislikeAction
  | resetAction
  | fetchEndAction
  | fetchingAction;
