import { UserEditableEntity, UserEntity } from "../../entities/UserEntity"

export const open = "REDUX_AUTH_OPEN"
export const close = "REDUX_AUTH_CLOSE"
export const forgot = "REDUX_AUTH_FORGOT"
export const authenticate = "REDUX_AUTH_AUTHENTICATE"
export const edit = "REDUX_AUTH_EDIT"
export const logout = "REDUX_AUTH_LOGOUT"
export const changePage = "REDUX_AUTH_CHANGE_PAGE"
export const fetching = "REDUX_AUTH_FETCHING"
export const fetchEnd = "REDUX_AUTH_FETCH_END"

export interface fetchingAction {
  type: typeof fetching
}

export interface fetchEndAction {
  type: typeof fetchEnd
}

export interface openAction {
  type: typeof open
}

export interface closeAction {
  type: typeof close
}

export interface editAction {
  type: typeof edit
  payload: { user: UserEditableEntity }
}

export interface authenticateAction {
  type: typeof authenticate
  payload: { user: UserEntity }
}

export interface forgotAction {
  type: typeof forgot
}

export interface logoutAction {
  type: typeof logout
}

export type AuthActionTypes =
  | fetchEndAction
  | fetchingAction
  | openAction
  | closeAction
  | authenticateAction
  | forgotAction
  | logoutAction
  | editAction
