import React from "react";
import { Snack } from "./Snack";
import { connector, ContainerProps } from "./containers/SnackBar.container";
import { SnackEntity } from "../../entities/SnackEntity";

export type SnackBarProps = {
  snacks: Array<SnackEntity>;
  onRemove?: Function;
};

export const SnackBarWrapper: React.FC<SnackBarProps> = ({
  snacks,
  onRemove = () => false,
}) => {
  return (
    <div className="fixed z-20 top-0 left-0 right-0 w-full h-0">
      {snacks.map(({ type, message, id }) => (
        <div className="pt-4 px-4" key={id} onClick={() => onRemove(id)}>
          <Snack type={type} message={message} />
        </div>
      ))}
    </div>
  );
};

export const SnackBarContainer: React.FC<ContainerProps> = (props) => (
  <SnackBarWrapper {...props} />
);

export const SnackBar = connector(SnackBarContainer);
