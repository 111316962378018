import * as constants from "./types";

interface LikesState {
  likes: Array<{ id: string; createdAt: Date }>;
  fetching: boolean;
}

const initialState: LikesState = {
  likes: [],
  fetching: false,
};

export function likesReducer(
  state = initialState,
  action: constants.LikesActionTypes
): LikesState {
  if (action.type === constants.reset) {
    return { ...initialState };
  }

  if (action.type === constants.storeLikes) {
    return {
      ...state,
      likes: action.payload.likes,
    };
  }
  
  if (action.type === constants.onLike) {
    return {
      ...state,
      likes: [...state.likes, { id: action.payload.id, createdAt: new Date() }],
    };
  }

  if (action.type === constants.onDislike) {
    return {
      ...state,
      likes: state.likes.filter(({ id }) => id !== action.payload.id),
    };
  }

  if (action.type === constants.fetching) {
    return {
      ...state,
      fetching: true,
    };
  }

  if (action.type === constants.fetchEnd) {
    return {
      ...state,
      fetching: false,
    };
  }

  return state;
}
