import { EditorActionTypes } from "./types"
import { PoemService } from "../../services/PoemService"
import { SyllaberService } from "../../services/SyllaberService"
import * as types from "./types"
import { SyllaberEntity } from "../../entities/SyllaberEntity"
import { categories } from "../../database/categories.json"

interface EditorState {
  haikuId: string | null
  editMode: boolean
  editionDataFetching: boolean
  isCategoryModalOpen: boolean
  content: string
  highlight: string
  indicator: "info" | "success" | "error"
  fetching: boolean
  rules: number[]
  base: SyllaberEntity
  augmented: SyllaberEntity
  selectedCategoryId: string | null
  categories: typeof categories
}

const initialState: EditorState = {
  haikuId: null,
  editMode: false,
  isCategoryModalOpen: false,
  editionDataFetching: false,
  content: "",
  highlight: "",
  indicator: "info",
  fetching: false,
  rules: [5, 7, 5],
  categories,
  selectedCategoryId: null,
  base: { characters: 0, lines: [], words: 0 },
  augmented: { characters: 0, lines: [], words: 0 },
}

export function editorReducer(
  state = initialState,
  action: EditorActionTypes
): EditorState {
  if (action.type === types.onEdit) {
    const poem = new PoemService(action.payload.content)
    const vers = poem.getVers()
    const base = SyllaberService.fromHtml(poem.surround())
    const highlight = SyllaberService.toHtml(
      SyllaberService.reconciliation(state.base, state.augmented),
      state.rules
    )

    const indicator =
      vers.length > 3 ? "error" : vers.length === 3 ? "success" : "info"

    return {
      ...state,
      base,
      content: poem.html,
      indicator,
      highlight,
    }
  }

  if (action.type === types.reset) {
    return {
      ...initialState,
    }
  }

  if (action.type === types.closeCategoryModal) {
    return {
      ...state,
      isCategoryModalOpen: false,
    }
  }

  if (action.type === types.openCategoryModal) {
    return {
      ...state,
      isCategoryModalOpen: true,
    }
  }

  if (action.type === types.setEditMode) {
    return {
      ...state,
      haikuId: action.payload.id,
      editMode: true,
    }
  }

  if (action.type === types.selectCategory) {
    return {
      ...state,
      selectedCategoryId: action.payload.id,
    }
  }

  if (action.type === types.storeAugmented) {
    return {
      ...state,
      augmented: action.payload.augmented,
    }
  }

  if (action.type === types.applyHighlight) {
    const highlight = SyllaberService.toHtml(
      SyllaberService.reconciliation(state.base, state.augmented),
      state.rules
    )

    return {
      ...state,
      highlight,
    }
  }

  if (action.type === types.fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === types.fetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === types.editionDataFetching) {
    return {
      ...state,
      editionDataFetching: true,
    }
  }

  if (action.type === types.editionDataFetchEnd) {
    return {
      ...state,
      editionDataFetching: false,
    }
  }

  return state
}
