export const open = "REDUX_MENU_OPEN";
export const close = "REDUX_MENU_CLOSE";

export interface openAction {
  type: typeof open;
}

export interface closeAction {
  type: typeof close;
}

export type MenuActionTypes = closeAction | openAction;
