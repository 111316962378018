import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { UserEntity, UserWithoutIdEntity } from "../../entities/UserEntity"
import * as types from "./types"
import * as snack from "../snack/actions"
import * as Models from "../../services/models"
import { RootState } from "../store"
import { actions } from "../actions"
import { navigate } from "gatsby"

export const open = () => ({
  type: types.open,
})

export const close = () => ({
  type: types.close,
})

export const authenticate = (
  payload: types.authenticateAction["payload"]
): types.AuthActionTypes => ({
  type: types.authenticate,
  payload,
})

export const edit = (
  payload: types.editAction["payload"]
): types.AuthActionTypes => ({
  type: types.edit,
  payload,
})

export const forgot = (): types.AuthActionTypes => ({
  type: types.forgot,
})

export const logout = (): types.AuthActionTypes => ({
  type: types.logout,
})

export const fetching = (): types.AuthActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.AuthActionTypes => ({
  type: types.fetchEnd,
})

const formatError = (code: string) => {
  const messages = {
    "auth/invalid-email": "L'email ne respecte pas le bon format",
    "auth/user-not-found": "L'utilisateur n'existe pas",
    "auth/invalid-password": "Le mot de passe ne respecte pas le bon format",
    "auth/user-exist": "Le nom d'utilisateur existe déjà",
    "auth/password-validation-failed": "Les mots de passe ne coincident pas",
    "auth/wrong-password": "Le mot de passe est invalide",
    "auth/weak-password": "Le mot de passe doit avoir au minimum 6 charactères",
    "auth/email-already-in-use": "L'email est déjà utilisé",
    "auth/not-doctrine": "Seulement les adresse email Doctrine sont autorisées",
    default: "Une erreur est survenue",
  }

  // @ts-ignore
  return messages[code] || messages.default
}

const catcher = (dispatcher: ThunkDispatch<any, any, any>) => (error: {
  code: string
  message: string
}) => {
  dispatcher(fetchEnd())
  dispatcher(snack.create({ message: formatError(error.code), type: "error" }))
}

export const fetchAuthenticate = (params: {
  email: string
  password: string
}): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
  dispatcher(fetching())

  return Models.authenticateWithPassword(params.email, params.password)
    .then(user => {
      dispatcher(fetchEnd())
      dispatcher(authenticate({ user }))
      // @ts-ignore
      const to = (window?.history?.state?.from || "/") as string
      navigate(to || "/")
      dispatcher(
        actions.snack.create({
          message: "Vous êtes bien connecté",
          type: "success",
        })
      )
      dispatcher(actions.likes.fetchLikes())
    })
    .catch(catcher(dispatcher))
}

export const fetchAuthenticateWithGoogle = (): ThunkAction<
  any,
  RootState,
  any,
  any
> => (dispatcher, getState) => {
  dispatcher(fetching())

  return Models.authenticateWithGoogle()
    .then(user => {
      // @ts-ignore
      dispatcher(authenticate({ user }))
      //@ts-ignore
      const to = (window?.history?.state?.from || "/") as string
      navigate(to || "/")
      dispatcher(
        actions.snack.create({
          message: "Vous êtes bien connecté",
          type: "success",
        })
      )
      dispatcher(fetchEnd())
      dispatcher(actions.likes.fetchLikes())
    })
    .catch(catcher(dispatcher))
}

export const fetchRegister = (
  info: UserWithoutIdEntity & {
    password: string
    passwordValidation: string
  }
): ThunkAction<any, any, any, any> => dispatcher => {
  if (info.password !== info.passwordValidation)
    return catcher(dispatcher)({
      code: "auth/password-validation-failed",
      message: "",
    })

  dispatcher(fetching())

  return Models.register(info)
    .then(user => {
      dispatcher(fetchEnd())
      dispatcher(authenticate({ user }))
      navigate("/")
    })
    .catch(catcher(dispatcher))
}

export const fetchForgot = (
  email: UserEntity["email"]
): ThunkAction<any, any, any, any> => dispatcher => {
  dispatcher(fetching())

  return Models.forgotPassword(email)
    .then(() => {
      return dispatcher(fetchEnd())
    })
    .catch(catcher(dispatcher))
}

export const fetchIsAuthenticated = (): ThunkAction<
  any,
  RootState,
  any,
  any
> => async dispatcher => {
  dispatcher(fetching())

  return Models.isAuthenticated()
    .then(user => {
      dispatcher(authenticate({ user }))
      dispatcher(fetchEnd())
      return { isConnected: true, user }
    })
    .catch(error => {
      dispatcher(fetchEnd())
      return { isConnected: false, user: null }
    })
}

export const fetchLogout = (): ThunkAction<
  any,
  RootState,
  any,
  any
> => dispatcher => {
  dispatcher(fetching())

  return Models.logout().then(() => {
    dispatcher(fetchEnd())
    dispatcher(logout())
    dispatcher(
      actions.snack.create({
        message: "Vous êtes bien déconnecté",
        type: "success",
      })
    )
    dispatcher(actions.likes.reset())
  })
}
