import { navigate } from "gatsby"
import { ThunkAction } from "redux-thunk"
import { RootState } from "../store"
import * as types from "./types"

export const close = (): types.MenuActionTypes => ({
  type: types.close,
})

export const open = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  navigate(window?.location.pathname + "#menu=open")
}
