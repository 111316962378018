import { ThunkAction } from "redux-thunk"

import * as types from "./types"
import * as Models from "../../services/models"
import { RootState } from "../store"
import { PoemEntity } from "../../entities/PoemEntity"
import { actions } from "../actions"
import sanitizeHtml from "sanitize-html"

export const storeUser = (
  payload: types.storeUserAction["payload"]
): types.ProfileActionTypes => ({
  type: types.storeUser,
  payload,
})

export const addPoem = (
  payload: types.addPoemAction["payload"]
): types.ProfileActionTypes => ({
  type: types.addPoem,
  payload,
})

export const updatePoem = (
  payload: types.updatePoemAction["payload"]
): types.ProfileActionTypes => ({
  type: types.updatePoem,
  payload,
})

export const storeVisitTheLanding = (): types.ProfileActionTypes => ({
  type: types.storeVisitTheLanding,
})

export const strorePoems = (
  payload: types.storePoemsAction["payload"]
): types.ProfileActionTypes => ({
  type: types.storePoems,
  payload,
})

export const storeProfile = (
  payload: types.storeProfileAction["payload"]
): types.ProfileActionTypes => ({
  type: types.storeProfile,
  payload,
})

export const updateBiography = (
  payload: types.updateBiographyAction["payload"]
): types.ProfileActionTypes => ({
  type: types.updateBiography,
  payload,
})

export const editBiography = (
  payload: types.editBiographyAction["payload"]
): types.ProfileActionTypes => ({
  type: types.editBiography,
  payload,
})

export const destroyPoem = (
  payload: types.destroyPoemAction["payload"]
): types.ProfileActionTypes => ({
  type: types.destroyPoem,
  payload,
})

export const fetching = (): types.ProfileActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.ProfileActionTypes => ({
  type: types.fetchEnd,
})

export const fetchUser = (
  userId: string
): ThunkAction<void, RootState, any, any> => dispatcher => {
  dispatcher(fetching())

  return Models.getUserInfo(userId).then(user => {
    dispatcher(fetchEnd())
    dispatcher(storeUser({ user }))
  })
}

export const fetchUserPoems = (): ThunkAction<void, RootState, any, any> => (
  dispatcher,
  getState
) => {
  const { auth } = getState()

  if (!auth.user || !auth.isConnected)
    return console.warn("the user is not connected")

  dispatcher(fetching())

  return Models.findPoemsByUserId(auth.user.id).then(poems => {
    dispatcher(strorePoems({ poems }))
    dispatcher(fetchEnd())
  })
}

export const fetchDestroyPoem = (
  poemId: PoemEntity["id"]
): ThunkAction<void, RootState, any, any> => (dispatcher, getState) => {
  const { auth } = getState()

  if (!auth.user || !auth.isConnected)
    return Promise.reject("the user is not connected")

  dispatcher(fetching())

  return Models.destroyPoem({ poemId }).then(() => {
    dispatcher(destroyPoem({ poemId }))
    dispatcher(actions.poems.remove({ poemId }))
    dispatcher(
      actions.snack.create({
        message: "Le haïku a bien été supprimé",
        type: "success",
      })
    )
    dispatcher(fetchEnd())
  })
}

export const fetchProfile = (): ThunkAction<void, RootState, any, any> => (
  dispatcher,
  getState
) => {
  const { auth } = getState()

  if (!auth.user || !auth.isConnected)
    return Promise.reject("the user is not connected")

  dispatcher(fetching())

  return Models.getProfile(auth.user.id).then(profile => {
    dispatcher(storeProfile({ profile }))
    dispatcher(fetchEnd())
  })
}

export const fetchUpdateUserInfo = (): ThunkAction<
  void,
  RootState,
  any,
  any
> => async (dispatcher, getState) => {
  const { auth, profile } = getState()

  if (!auth.user || !auth.isConnected)
    return Promise.reject("the user is not connected")

  dispatcher(fetching())

  const biography = sanitizeHtml(profile.editor.biography, { allowedTags: [] })

  await Models.updateBiography({
    userId: auth.user.id,
    content: biography,
  })
  await Models.updateUserInfo(auth.user.id, "firstName", auth.editor.firstName)
  await Models.updateUserInfo(auth.user.id, "lastName", auth.editor.lastName)

  dispatcher(storeProfile({ profile: { ...profile.profile, biography } }))
  dispatcher(
    actions.auth.authenticate({
      user: {
        ...auth.user,
        firstName: auth.editor.firstName,
        lastName: auth.editor.lastName,
      },
    })
  )

  dispatcher(fetchEnd())

  dispatcher(
    actions.snack.create({
      message:
        "Les modifications ont bien été enregistrées et seront actualisées dans 24h maximum.",
      type: "success",
      timeout: 3000,
    })
  )
}
