import React from "react";

export type SnackProps = {
  type: "info" | "success" | "error" | "warning";
  message: string | null;
  onClose?: Function;
};

const InfoIcon = () => (
  <svg
    className="h-5 w-5 text-blue-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
      clipRule="evenodd"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg
    className="h-5 w-5 text-red-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clipRule="evenodd"
    />
  </svg>
);

const WarningIcon = () => (
  <svg
    className="h-5 w-5 text-yellow-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
      clipRule="evenodd"
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    className="h-5 w-5 text-green-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
);

const getTextColor = (type: SnackProps["type"]) => {
  if (type === "error") return "text-red-800";
  if (type === "warning") return "text-yellow-800";
  if (type === "success") return "text-green-800";
  return "text-blue-800";
};

const getIcon = (type: SnackProps["type"]) => {
  if (type === "error") return ErrorIcon;
  if (type === "warning") return WarningIcon;
  if (type === "success") return SuccessIcon;
  return InfoIcon;
};

const getBgColor = (type: SnackProps["type"]) => {
  if (type === "error") return "bg-red-100";
  if (type === "warning") return "bg-yellow-100";
  if (type === "success") return "bg-green-100";
  return "bg-blue-100";
};

const getButtonColor = (type: SnackProps["type"]) => {
  if (type === "error") return "text-red-500 hover:bg-red-200 focus:bg-red-200";
  if (type === "warning")
    return "text-yellow-500 hover:bg-yellow-200 focus:bg-yellow-200";
  if (type === "success")
    return "text-green-500 hover:bg-green-200 focus:bg-green-200";
  return "text-blue-500 hover:bg-blue-200 focus:bg-blue-200";
};

export const Snack: React.FC<SnackProps> = ({
  message,
  type,
  onClose = () => false,
}) => {
  const Icon = getIcon(type);

  return (
    <div className={`rounded-md ${getBgColor(type)} p-4 shadow`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon />
        </div>
        <div className="ml-3">
          <p className={`text-sm leading-5 font-medium ${getTextColor(type)}`}>
            {message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={() => onClose()}
              className={`${getButtonColor(
                type
              )} inline-flex rounded-md p-1.5  focus:outline-none  transition ease-in-out duration-150`}
              aria-label="Dismiss"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
