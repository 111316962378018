import { ThunkAction, ThunkDispatch } from "redux-thunk"
import * as types from "./types"
import * as snack from "../snack/actions"
import * as Models from "../../services/models"
import { RootState } from "../store"
import { actions } from "../actions"
import { PoemService } from "../../services/PoemService"
import { syllaber } from "../../services/api"
import throttle from "lodash/throttle"
import { SyllaberService } from "../../services/SyllaberService"
import { navigate } from "gatsby"

export const closeCategoryModal = (): types.EditorActionTypes => ({
  type: types.closeCategoryModal,
})

export const openCategoryModal = (): types.EditorActionTypes => ({
  type: types.openCategoryModal,
})

export const storeEdit = (
  payload: types.onEditAction["payload"]
): types.EditorActionTypes => ({
  type: types.onEdit,
  payload,
})

export const selectCategory = (
  payload: types.selectCategoryAction["payload"]
): types.EditorActionTypes => ({
  type: types.selectCategory,
  payload,
})

export const setEditMode = (
  payload: types.setEditModeAction["payload"]
): types.EditorActionTypes => ({
  type: types.setEditMode,
  payload,
})

export const reset = (): types.EditorActionTypes => ({
  type: types.reset,
})

export const storeAugmented = (
  payload: types.storeAugmentedAction["payload"]
): types.EditorActionTypes => ({
  type: types.storeAugmented,
  payload,
})

export const applyHighlight = (): types.EditorActionTypes => ({
  type: types.applyHighlight,
})

export type fetchingReturnType = {
  type: typeof types.fetching
}

export const fetching = (): types.EditorActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.EditorActionTypes => ({
  type: types.fetchEnd,
})

export const editionDataFetching = (): types.EditorActionTypes => ({
  type: types.editionDataFetching,
})

export const editionDataFetchEnd = (): types.EditorActionTypes => ({
  type: types.editionDataFetchEnd,
})

const formatError = (code: string) => {
  switch (code) {
    case "auth/invalid-email":
      return "L'email ne respecte pas le bon format"
    case "auth/user-not-found":
      return "L'utilisateur n'existe pas"
    case "auth/invalid-password":
      return "Le mot de passe ne respecte pas le bon format"
    case "auth/user-exist":
      return "Le nom d'utilisateur existe déjà"
    case "auth/password-validation-failed":
      return "Les mots de passe ne coincident pas"
    case "auth/wrong-password":
      return "Le mot de passe est invalide"
    case "auth/email-already-in-use":
      return "L'email est déjà utilisé"
    case "auth/not-doctrine":
      return "Seulement les adresse email Doctrine sont autorisées"
    default:
      return "Une erreur est survenue"
  }
}

const catcher = (dispatcher: ThunkDispatch<any, any, any>) => (error: {
  code: string
  message: string
}) => {
  dispatcher(fetchEnd())
  return dispatcher(
    snack.create({ message: formatError(error.code), type: "error" })
  )
}

const debounceEdit = throttle(async (dispatcher, content) => {
  const augmented = await syllaber(content)
  dispatcher(storeAugmented({ augmented }))
  dispatcher(applyHighlight())
}, 500)

export const edit = (
  payload: types.onEditAction["payload"]
): ThunkAction<any, RootState, any, any> => dispatcher => {
  dispatcher(storeEdit(payload))
  dispatcher(applyHighlight())

  const content = SyllaberService.htmlToText(payload.content)

  debounceEdit(dispatcher, content)
}

export const fetchPublish = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  const { auth, editor } = getState()

  if (editor.fetching) return null

  if (!auth.isConnected || !auth.user)
    return navigate("/se-connecter/", {
      state: {
        from: "/ecrire/",
        action: "publish",
      },
    })

  const poem = new PoemService(editor.content)

  if (poem.getVers().length !== 3)
    return dispatcher(
      actions.snack.create({
        message: "Vous devez avoir trois vers pour publier",
        type: "error",
      })
    )

  const category = editor.categories.find(
    ({ id }) => editor.selectedCategoryId === id
  )

  if (!category)
    return dispatcher(
      actions.snack.create({
        message: "Vous devez selectionner une catégorie pour publier",
        type: "error",
      })
    )

  dispatcher(fetching())

  return Models.publish({ html: editor.content, user: auth.user, category })
    .then(poem => {
      dispatcher(fetchEnd())
      dispatcher(actions.poems.add({ poem }))
      dispatcher(actions.profile.addPoem({ poem }))
      dispatcher(actions.editor.reset())
      actions.modal.open(dispatcher)
      navigate("/")
    })
    .catch(catcher(dispatcher))
}

export const fetchSave = ({
  from,
}: {
  from?: string | null
}): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
  const { editor } = getState()

  if (editor.editMode) return dispatcher(fetchSaveEdition({ from }))
  return dispatcher(fetchPublish())
}

export const fetchSaveEdition = (props: {
  from?: string
}): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
  const { auth, editor } = getState()

  if (editor.fetching) return null

  const poem = new PoemService(editor.content)

  if (poem.getVers().length !== 3)
    return dispatcher(
      actions.snack.create({
        message: "Vous devez avoir trois vers pour publier",
        type: "error",
      })
    )

  const category = editor.categories.find(
    ({ id }) => editor.selectedCategoryId === id
  )

  if (!category)
    return dispatcher(
      actions.snack.create({
        message: "Vous devez selectionner une catégorie pour publier",
        type: "error",
      })
    )

  dispatcher(fetching())

  return Models.updateHaiku({
    html: editor.content,
    user: auth.user,
    id: editor.haikuId,
    category,
  })
    .then(poem => {
      dispatcher(fetchEnd())
      dispatcher(actions.poems.update({ poem }))
      dispatcher(actions.profile.updatePoem({ poem }))
      dispatcher(actions.editor.reset())
      actions.modal.open(dispatcher)
      navigate(props.from || "/")
    })
    .catch(catcher(dispatcher))
}

export const getHaikuAndStartEdition = ({
  id,
}: {
  id: string | null
}): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
  const { auth } = getState()

  // @todo voir ce qu'on fait dans ce cas là
  if (!id) return navigate("/")

  if (!auth.isConnected || !auth.user)
    return navigate("/se-connecter/", {
      state: {
        from: "/editer/",
        action: "edit",
      },
    })

  dispatcher(editionDataFetching())

  return Models.getHaikuById({ id })
    .then(haiku => {
      if (!haiku) {
        navigate("/")
        return dispatcher(
          actions.snack.create({
            type: "error",
            message: "L'haïku que vous souhaitez modifier n'existe plus",
            timeout: 5000,
          })
        )
      }

      if (auth.user.id !== haiku.user.id) {
        navigate("/")
        return dispatcher(
          actions.snack.create({
            type: "error",
            message:
              "L'haïku que vous souhaitez modifier ne vous appartient pas",
            timeout: 5000,
          })
        )
      }

      dispatcher(actions.editor.reset())
      dispatcher(setEditMode({ id }))
      dispatcher(selectCategory({ id: haiku.category?.id }))
      dispatcher(actions.editor.edit({ content: haiku.html }))
    })
    .catch(catcher(dispatcher))
}

export const checkIfEditorShouldBeReset = (props: {
  from: "editor" | "creator"
}): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
  const { editor } = getState()

  if (props.from === "creator" && editor.editMode) dispatcher(reset())
  if (props.from === "editor" && !editor.editMode) dispatcher(reset())
}

export const checkCanOpenCategoryModal = (): ThunkAction<
  any,
  RootState,
  any,
  any
> => (dispatcher, getState) => {
  const { editor } = getState()

  const poem = new PoemService(editor.content)

  if (poem.getVers().length !== 3)
    return dispatcher(
      actions.snack.create({
        message: "Vous devez avoir trois vers pour publier",
        type: "error",
      })
    )

  return dispatcher(openCategoryModal())
}
