import { PoemEntity } from "../../entities/PoemEntity"
import { UserEntity } from "../../entities/UserEntity"
import { ProfileEntity } from "../../entities/ProfileEntity"

export const storeUser = "REDUX_PROFILE_STORE_USER"
export const addPoem = "REDUX_PROFILE_ADD_POEM"
export const updatePoem = "REDUX_PROFILE_UPDATE_POEM"
export const storePoems = "REDUX_PROFILE_STORE_POEMS"
export const storeProfile = "REDUX_PROFILE_STORE_PROFILE"
export const updateBiography = "REDUX_PROFILE_UPDATE_BIOGRAPHY"
export const editBiography = "REDUX_PROFILE_EDIT_BIOGRAPHY"
export const storeVisitTheLanding = "REDUX_PROFILE_STORE_VISIT_LANDING"
export const destroyPoem = "REDUX_PROFILE_DESTROY_POEM"
export const fetching = "REDUX_PROFILE_FETCHING"
export const fetchEnd = "REDUX_PROFILE_FETCH_END"

export interface storeUserAction {
  type: typeof storeUser
  payload: { user: UserEntity }
}

export interface storeProfileAction {
  type: typeof storeProfile
  payload: { profile: ProfileEntity }
}

export interface updateBiographyAction {
  type: typeof updateBiography
  payload: { biography: string }
}

export interface editBiographyAction {
  type: typeof editBiography
  payload: { biography: string }
}

export interface addPoemAction {
  type: typeof addPoem
  payload: { poem: PoemEntity }
}

export interface updatePoemAction {
  type: typeof updatePoem
  payload: { poem: PoemEntity }
}

export interface storeVisitTheLandingAction {
  type: typeof storeVisitTheLanding
}

export interface storePoemsAction {
  type: typeof storePoems
  payload: { poems: PoemEntity[] }
}
export interface destroyPoemAction {
  type: typeof destroyPoem
  payload: { poemId: PoemEntity["id"] }
}

export interface fetchingAction {
  type: typeof fetching
}

export interface fetchEndAction {
  type: typeof fetchEnd
}

export type ProfileActionTypes =
  | storeUserAction
  | storeProfileAction
  | updatePoemAction
  | updateBiographyAction
  | editBiographyAction
  | addPoemAction
  | storeVisitTheLandingAction
  | storePoemsAction
  | destroyPoemAction
  | fetchEndAction
  | fetchingAction
