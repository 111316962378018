import { PoemEntity } from "../../entities/PoemEntity"
import { ProfileEntity } from "../../entities/ProfileEntity"
import * as constants from "./types"

interface ProfileState {
  user: {}
  profile: ProfileEntity
  editor: ProfileEntity
  poems: PoemEntity[]
  fetching: boolean
}

const initialState: ProfileState = {
  user: {},
  fetching: false,
  profile: { biography: null },
  editor: { biography: "" },
  poems: [],
}

export function profileReducer(
  state = initialState,
  action: constants.ProfileActionTypes
): ProfileState {
  if (action.type === constants.storeUser) {
    return {
      ...state,
      user: action.payload.user,
    }
  }

  if (action.type === constants.addPoem) {
    return {
      ...state,
      poems: [{ ...action.payload.poem }, ...state.poems],
    }
  }
  if (action.type === constants.updatePoem) {
    return {
      ...state,
      poems: state.poems.map(poem => {
        if (poem.id === action.payload.poem.id) return action.payload.poem
        return poem
      }),
    }
  }

  if (action.type === constants.storePoems) {
    return {
      ...state,
      poems: action.payload.poems,
    }
  }

  if (action.type === constants.destroyPoem) {
    return {
      ...state,
      poems: state.poems.filter(({ id }) => id !== action.payload.poemId),
    }
  }

  if (action.type === constants.fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === constants.fetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === constants.storeProfile) {
    return {
      ...state,
      profile: action.payload.profile,
      editor: action.payload.profile,
    }
  }

  if (action.type === constants.updateBiography) {
    return {
      ...state,
      profile: {
        ...state.profile,
        biography: action.payload.biography,
      },
      editor: {
        ...state.editor,
        biography: action.payload.biography,
      },
    }
  }

  if (action.type === constants.editBiography) {
    return {
      ...state,
      editor: {
        ...state.editor,
        biography: action.payload.biography,
      },
    }
  }

  return state
}
