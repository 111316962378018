import { Firebase, Database } from "./firebase"

import {
  filter,
  pipe,
  toLower,
  contains,
  prop,
  slice,
  flatten,
  reject,
  propEq,
} from "ramda"
import { UserEntity, UserWithoutIdEntity } from "../entities/UserEntity"
import { PoemEntity } from "../entities/PoemEntity"
import { LikeEntity } from "../entities/LikeEntity"
import { ProfileEntity } from "../entities/ProfileEntity"
import { ThemeEntity } from "../entities/ThemeEntity"

export const initializeCache = (func: Function) => {
  const cache = {}
  return func(cache)
}

export function mapQuerySnapshot<T>(querySnapshot: any): Array<T> {
  const data: any[] = []

  querySnapshot.forEach((doc: any) => data.push(doc.data()))

  return data
}

export const getDataFromCache = initializeCache(
  (cache: { [x: string]: any }) => {
    //@ts-ignore
    return collection => {
      if (cache.hasOwnProperty(collection))
        return Promise.resolve(cache[collection])
      return Database.collection(collection)
        .get()
        .then(doc => {
          cache[collection] = doc
          return doc
        })
    }
  }
)

export const authenticateWithGoogle = () => {
  const provider = new Firebase.auth.GoogleAuthProvider()
  return Firebase.auth()
    .signInWithPopup(provider)
    .then(result => {
      const user = {
        ...(result.additionalUserInfo?.profile || {}),
        ...(result.user || {}),
      } as UserEntity

      //@ts-ignore
      return createOrUpdateUser(user.uid, {
        //@ts-ignore
        id: user.uid,
        //@ts-ignore
        email: user.email,
        //@ts-ignore
        username: user.name,
        //@ts-ignore
        lastName: user.family_name,
        //@ts-ignore
        firstName: user.given_name,
      })
    })
}

export const authenticateWithPassword = (email: string, password: string) => {
  return Firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .then(isAuthenticated)
}

export const logout = () => {
  return Firebase.auth().signOut()
}

export const forgotPassword = (email: string) => {
  return Firebase.auth().sendPasswordResetEmail(email)
}

export const extractUserInfo = ({ uid }: { uid: string }) => ({ id: uid })

export const isAuthenticated = (): Promise<UserEntity> => {
  return new Promise((resolve, reject) => {
    Firebase.auth().onAuthStateChanged(function (user) {
      if (user) return resolve(getUserInfo(user.uid))
      return reject("The user is not connected")
    })
  })
}

const userSchema = (data: { [x: string]: any }) => ({
  id: "",
  username: "",
  lastName: "",
  firstName: "",
  ...data,
})

export const getUserInfo = (id: string) => {
  return Database.collection("users")
    .doc(id)
    .get()
    .then(ref => {
      if (!ref.exists) return Promise.reject("The user does not exist")

      return {
        ...ref.data(),
      } as UserEntity
    })
}

export const createOrUpdateUser = (id: string, data: { [x: string]: any }) => {
  return getUserInfo(id)
    .then(info => {
      const updateSchema = userSchema({ ...info, ...data, id })
      return Database.collection("users")
        .doc(id)
        .update(updateSchema)
        .then(() => updateSchema)
    })
    .catch(e => {
      const insertSchema = userSchema({ ...data, id })
      return Database.collection("users")
        .doc(id)
        .set(insertSchema)
        .then(() => insertSchema)
    })
}

export const register = (info: UserWithoutIdEntity & { password: string }) => {
  return checkUserAlreadyExist(info.email).then(exists => {
    if (exists) return Promise.reject({ code: "auth/user-exist" })

    return (
      Firebase.auth()
        .createUserWithEmailAndPassword(info.email, info.password)
        .then(({ user }) => {
          if (!user?.uid)
            throw new Error("the user.uid is not provided by Google")

          return createOrUpdateUser(user.uid, {
            id: user.uid,
            username: info.username,
            lastName: info.lastName,
            firstName: info.firstName,
          })
        })
        //@ts-ignore
        .then(user => ({ ...user, email: info.email, id: user.uid }))
    )
  })
}

export const getUsersByName = (userId: string, name = "") => {
  return (
    getDataFromCache("users")
      .then(mapQuerySnapshot)
      .then(reject(propEq("id", userId)))
      //@ts-ignore
      .then(filter(pipe(prop("username"), toLower, contains(toLower(name)))))
      .then(slice(0, 10))
  )
}

export const checkUserAlreadyExist = (email: string) => {
  return Database.collection("users")
    .where("email", "==", email)
    .get()
    .then(e => !e.empty)
}

export const getLikesFromUser = (userId: string) => {
  return Database.collection("likes")
    .doc(userId)
    .collection("poems")
    .get()
    .then(snapshot => {
      return mapQuerySnapshot<LikeEntity>(snapshot)
    })
}

export const like = (params: { poemId: string; userId: string }) => {
  return Database.collection("likes")
    .doc(params.userId)
    .collection("poems")
    .doc(params.poemId)
    .set({ createdAt: new Date(), id: params.poemId })
}

export const updateBiography = async (params: {
  userId: string
  content: string
}) => {
  const exists = await checkIfProfileExists(params.userId)
  const doc = Database.collection("profiles").doc(params.userId)

  if (exists) return doc.update({ biography: params.content })

  return doc.set({ biography: params.content })
}

export const getProfile = (id: string): Promise<ProfileEntity> => {
  return Database.collection("profiles")
    .doc(id)
    .get()
    .then(response => {
      if (!response.exists) return { biography: null }

      const data = response.data()

      return {
        biography: data.biography,
      }
    })
}

export const checkIfProfileExists = (id: string): Promise<boolean> => {
  return Database.collection("profiles")
    .doc(id)
    .get()
    .then(response => response.exists)
}

export const dislike = (params: { poemId: string; userId: string }) => {
  return Database.collection("likes")
    .doc(params.userId)
    .collection("poems")
    .doc(params.poemId)
    .delete()
}

export const destroyPoem = (params: { poemId: string }) => {
  return Database.collection("poems").doc(params.poemId).delete()
}

export const getAllUsers = () => {
  return getDataFromCache("users").then(mapQuerySnapshot)
}

export const updateUserInfo = (userId: string, label: string, value: any) => {
  return Database.collection("users")
    .doc(userId)
    .update({ [label]: value })
}

export const findPoems = async (params: { limit: number }) => {
  return Database.collection("poems")
    .orderBy("createdAt", "desc")
    .limit(params.limit)
    .get()
    .then(snapshot => {
      return mapQuerySnapshot<PoemEntity>(snapshot)
    })
}

export const getHaikuById = async (params: { id: string }) => {
  return Database.collection("poems")
    .doc(params.id)
    .get()
    .then(snapshot => {
      if (!snapshot.exists) return null
      return snapshot.data() as PoemEntity
    })
}

export const findPoemsByUserId = async (userId: UserEntity["id"]) => {
  return Database.collection("poems")
    .where("user.id", "==", userId)
    .orderBy("createdAt", "desc")
    .get()
    .then(snapshot => {
      return mapQuerySnapshot<PoemEntity>(snapshot)
    })
}

export const or = (collections: Array<any>) => {
  const queries = collections.map(collection => {
    return collection.get().then(mapQuerySnapshot)
  })

  return Promise.all(queries).then(flatten)
}

export const publish = async ({
  html,
  user,
  category,
}: {
  html: string
  user: UserEntity
  category: ThemeEntity
}) => {
  const id = Database.collection("poems").doc()
  const entity = {
    html,
    user,
    id: id.id,
    category,
    createdAt: new Date(),
    updatedAt: new Date(),
  }
  return id.set(entity).then(() => {
    return entity
  })
}

export const updateHaiku = async ({
  id,
  html,
  user,
  category,
}: {
  id: string
  html: string
  user: UserEntity
  category: ThemeEntity
}) => {
  const entity = { html, category, updatedAt: new Date() }
  await Database.collection("poems").doc(id).update(entity)
  return { ...entity, user, id }
}
