import { SnackEntity } from "../../entities/SnackEntity";

export const store = "REDUX_SNACK_STORE";
export const remove = "REDUX_SNACK_REMOVE";

export interface storeAction {
  type: typeof store;
  payload: SnackEntity;
}

export interface removeAction {
  type: typeof remove;
  payload: { id: SnackEntity["id"] };
}

export type SnackActionTypes = storeAction | removeAction;
