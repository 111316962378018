import sanitizeHtml from "sanitize-html";

export class PoemService {
  private element: HTMLDivElement;
  public html: string;

  constructor(html: string) {
    this.element = document.createElement("div");
    this.element.innerHTML = this.sanitize(html);
    this.html = this.element.innerHTML;
  }

  getVers() {
    const vers: string[] = [];

    this.element.childNodes.forEach((e) => {
      const text = e.textContent?.trim();
      if (text && text.length > 0) vers.push(text);
    });

    return vers;
  }

  getNumberChars() {
    const vers = this.getVers();

    return vers.reduce((initial, value) => value.trim().length + initial, 0);
  }

  sanitize(html: string) {
    const sanitized = sanitizeHtml(html, { allowedTags: ["div", "br"] });
    const element = document.createElement("div");
    element.innerHTML = sanitized;

    element.childNodes.forEach((e) => {
      if (e.nodeName === "#text") {
        const content = e.textContent;
        const div = document.createElement("div");
        div.innerHTML = content || "";
        e.replaceWith(div);
      }
    });

    return element.innerHTML;
  }

  surround() {
    const clone = document.createElement("div");

    clone.innerHTML = this.element.innerHTML;

    const divs = clone.querySelectorAll("div");

    divs.forEach((e) => {
      const content = e.textContent;
      const divContent =
        content
          ?.split(" ")
          .map((text) => `<span class="word"><span class="cuttout">${text}</span></span>`)
          .join(" ") || "";

      e.innerHTML = divContent;
    });

    const html = clone.innerHTML;

    clone.remove();

    return html;
  }
}
