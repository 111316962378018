import firebase from "gatsby-plugin-firebase"

const db = firebase.firestore()

const mockAnalytics: Pick<firebase.analytics.Analytics, "logEvent"> = {
  logEvent: (...props: any): any => {},
}

db.settings({})

export const Database = db
export const Firebase = firebase
export const analytics =
  process.env.NODE_ENV === "production"
    ? () => firebase.analytics()
    : () => mockAnalytics
